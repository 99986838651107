import Helmet from "react-helmet";
import React from "react";

const Metadata = ({ title = "StandupWizard", imageOverride = false }) => {
  const image = "https://standupwizard-static.s3.amazonaws.com/share.jpg";
  const description =
    "Keep your team accountable and running effectively by automatically collecting status updates directly in Slack";
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://standupwizard.com/" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={imageOverride ? imageOverride : image}
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content="https://standupwizard.com/" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content={imageOverride ? imageOverride : image}
      />
    </Helmet>
  );
};

export default Metadata;
