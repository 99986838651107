import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import BlogHero from "../components/blog-hero";
import CTA from "../components/call-to-action";
import Footer from "../components/footer";
import Metadata from "../components/metadata";

const BlogIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes;

  const firstPost = posts.slice(0, 1)[0];
  const restPosts = posts.slice(1);

  return (
    <div className="bg-white">
      <Metadata title="Blog - StandupWizard" />
      <BlogHero />
      <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:px-10">
        <h2 className="text-3xl font-extrabold text-gray-900">
          Latest Blog Posts
        </h2>
        <div className="mt-8 mb-12 lg:flex">
          <div className="lg:w-4/6 lg:pr-10">
            <Link to={firstPost.fields.slug}>
              <GatsbyImage
                className="max-w-full h-auto rounded-md"
                alt={firstPost.frontmatter.title}
                image={
                  firstPost.frontmatter.featuredImage.childImageSharp
                    .gatsbyImageData
                }
              />
            </Link>
          </div>
          <div className="lg:w-2/6 lg:flex lg:flex-col lg:justify-center">
            <div>
              <span className="mt-8 block text-sm text-gray-400 lg:mt-0">
                {firstPost.frontmatter.date}
              </span>
              <Link to={firstPost.fields.slug}>
                <h3 className="text-2xl mb-4 font-extrabold text-gray-900 tracking-tight sm:text-4xl hover:text-indigo-500">
                  {firstPost.frontmatter.title}
                </h3>
              </Link>
              <p className="text-md text-gray-500">
                {firstPost.frontmatter.description}
              </p>
              <Link
                className="inline-block mt-4 text-sm text-indigo-500 hover:underline"
                to={firstPost.fields.slug}
              >
                Read Article &rarr;
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-8 md:grid md:grid-cols-2 md:gap-8">
          {restPosts.map((post) => (
            <div key={post.fields.slug}>
              <div>
                <Link to={post.fields.slug}>
                  <GatsbyImage
                    className="max-w-full h-auto rounded-md"
                    alt={post.frontmatter.title}
                    image={
                      post.frontmatter.featuredImage.childImageSharp
                        .gatsbyImageData
                    }
                  />
                </Link>
              </div>
              <div>
                <div className="mb-8">
                  <span className="block text-xs mt-4 text-gray-400">
                    {post.frontmatter.date}
                  </span>
                  <Link to={post.fields.slug}>
                    <h3 className="mb-3 text-xl font-extrabold text-gray-900 tracking-tight sm:text-3xl hover:text-indigo-500">
                      {post.frontmatter.title}
                    </h3>
                  </Link>
                  <p className="text-sm text-gray-500">
                    {post.frontmatter.description}
                  </p>
                  <Link
                    className="inline-block mt-4 text-sm text-indigo-500 hover:underline"
                    to={post.fields.slug}
                  >
                    Read Article &rarr;
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <CTA />
      <Footer />
    </div>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
